import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { Text } from '@gravity/text'
import styled from 'styled-components'
import { useCancelBatchMutation } from '../../hooks/useCancelBatch'
import { useHistory, useParams } from 'react-router-dom'
import { useToast } from '@gravity/toast'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
interface OverrideProps {
  onClose: () => void
  open: boolean
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const OverrideBatch = ({ open, onClose }: OverrideProps) => {
  const { toast } = useToast()
  const { mutate: cancelBatchMutate, isLoading } = useCancelBatchMutation()
  const { batchID } = useParams<{ batchID: string }>()
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = async () => {
    cancelBatchMutate(
      { batchID },
      {
        onSuccess: () => {
          history.push(`/${schoolSlug}/migrar-dados/`)
        },
        onError: error => {
          handleClose()
          toast({
            title: 'Erro ao tentar substituir a planilha',
            type: 'error',
          })
          console.error(error)
        },
      }
    )
  }

  return (
    <Dialog
      size={2}
      title="Ao substituir planilha, você vai perder estes dados"
      backdrop
      open={open}
      onOpenChange={value => {
        if (!value) {
          handleClose()
        }
      }}
      cancelButton={<Button variant="ghost">Cancelar</Button>}
      actionButton={
        <Button variant="solid" type="submit" onClick={() => handleSubmit()} loading={isLoading}>
          Substituir mesmo assim
        </Button>
      }
      content={
        <Content>
          <Text>
            Você vai voltar para a primeira etapa e quando adicionar uma nova planilha, os dados
            atuais não serão salvos.
          </Text>
          <Text style={{ marginTop: 32 }}>
            Você vai visualizar e poder corrigir apenas os dados da nova planilha.
          </Text>
        </Content>
      }
    />
  )
}
