import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const LoadingPage = () => {
  return (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  )
}
