import styled from 'styled-components'
import { Link as LinkEl } from '../Link'

export const Link = styled(LinkEl)`
  &:hover,
  &:active {
    .title,
    .content {
      color: ${props => props.theme.gravity.colors['colors-text-main-5']};
    }
  }

  &:link,
  &:visited {
    color: ${props => props.theme.gravity.colors['colors-text-main-2']};
    display: flex;
    flex-direction: column;

    padding: ${props => props.theme.gravity.spacing[4]} ${props => props.theme.gravity.spacing[8]};
    border-radius: ${props => props.theme.gravity.borderRadius[2]};
    text-decoration: none;

    height: 100%;
  }

  &:hover {
    background: ${props => props.theme.gravity.colors['colors-background-neutral-5']};
  }

  &:focus-visible {
    outline: ${props => props.theme.gravity.colors['colors-interaction-primary-4']} solid 1px;
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: ${props => props.theme.gravity.spacing[4]} ${props => props.theme.gravity.spacing[8]};
  border-radius: ${props => props.theme.gravity.borderRadius[2]};
  border: 1px solid ${props => props.theme.gravity.colors['colors-border-neutral-3']};

  &.skeleton {
    gap: ${props => props.theme.gravity.spacing[8]};
  }

  height: 100%;
`
