import { Text } from '@gravity/text'
import { Button } from '@gravity/button'
import { Dialog } from '@gravity/dialog'
import { CreateProductDeliveryRequest } from '@/modules/account-reports/services/product_deliveries/types'

interface Props {
  closeDialog: () => void
  contract_id: string
  handleClick: (input: CreateProductDeliveryRequest) => void
  max_deliveries: number
  stage: number
  visible: boolean
}

export const ConfirmationDialog = ({
  visible,
  stage,
  contract_id,
  max_deliveries,
  closeDialog,
  handleClick,
}: Props) => {
  const handleDialogOpenChange = (open: boolean) => {
    if (!open && visible) closeDialog()
  }
  const input: CreateProductDeliveryRequest = {
    contract_id: contract_id,
    delivery_stage: stage,
    max_deliveries,
  }

  return (
    <Dialog
      title="Confirmar a entrega?"
      backdrop
      open={visible}
      size={1}
      onOpenChange={handleDialogOpenChange}
      cancelButton={
        <Button variant="ghost" onClick={closeDialog}>
          Cancelar
        </Button>
      }
      actionButton={
        <Button size={2} onClick={() => handleClick(input)}>
          Confirmar
        </Button>
      }
      content={
        <div>
          <Text variant="body-2-regular">
            {`Ao confirmar, o material didático do ${stage}º bimestre será marcado como `}
            <b>entregue</b>. Essa ação não pode ser desfeita.
          </Text>
        </div>
      }
    />
  )
}
