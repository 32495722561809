import { useQuery } from '@tanstack/react-query'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { AxiosError } from 'axios'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApiClient } from '@/shared/hooks/useApiClient'
import { accountReportsService } from '@/modules/account-reports/services/account-reports'

export const useGetFamilyRegularizationReport = () => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const { apiClient } = useApiClient()
  const service = accountReportsService(apiClient.getClients().privateApi)

  return useQuery({
    queryKey: ['family-regularization', school?.id],
    queryFn: async () => {
      const { data } = await service.fetchFamilyRegularizationReport({ id: school?.id ?? '' })

      return {
        url: `${data.url}#font=Roboto&bordered=false&titled=false`,
      }
    },

    onError: (error: AxiosError) => {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.FAMILY_REGULARIZATION_REPORT,
          name: EventDispatcherEvents.LOADING_ERROR,
          action: 'callback',
          customProperties: {
            $page_name: 'Relatório de regularização das famílias',
            $error_message: error?.message,
            $error_response: error?.response?.data,
          },
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
  })
}
