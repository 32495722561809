import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@olaisaac/design-system'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Icon from '@mdi/react'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag, useJWT } from 'src/shared/hooks'

import * as Styled from './styles'
import { Tabs } from '@gravity/tabs'
import { TeachingMaterialSalesReport } from './SalesReport'
import {
  LOGISTICS_TABS,
  LOGISTICS_TEACHING_MATERIAL_TABS,
  LogisticsTeachingMaterialTab,
} from './constants'
import { Text } from '@gravity/text'
import { TeachingMaterialLogisticsPage } from './LogisticsPage'
import { useQuery } from '@/shared/hooks/useQuery'

export const TeachingMaterialIncomeReport = () => {
  const { schoolSlug, school } = useSelectedSchool()
  const { push } = useHistory()
  const { query, setOnQueryParam } = useQuery()
  const { getUserRolesBySchool, getBackofficeUserRoles, isBackofficeUser } = useJWT()
  const [activeTab, setActiveTab] = useState<LOGISTICS_TEACHING_MATERIAL_TABS>(
    LOGISTICS_TEACHING_MATERIAL_TABS.SALES_REPORT
  )
  useEffect(() => {
    if (query?.get('tab')?.toString() === LOGISTICS_TEACHING_MATERIAL_TABS.DELIVERY_REGISTRY) {
      setActiveTab(LOGISTICS_TEACHING_MATERIAL_TABS.DELIVERY_REGISTRY)
    }
  }, [])

  const isTeachingMaterialLogisticsEnabled = useUnleashFlag(
    UnleashFlags.ISP_225_ENABLE_TEACHING_MATERIAL_LOGISTICS_PAGE
  )

  const goToReportsList = () => push(`/${schoolSlug}/relatorios`)
  let shouldShowReport =
    getBackofficeUserRoles().has('material_didatico_relatorio_completo') ||
    getBackofficeUserRoles().has('material_didatico_relatorio_basico')
  if (!isBackofficeUser) {
    shouldShowReport =
      getUserRolesBySchool(school?.id ?? '').has('material_didatico_relatorio_completo') ||
      getUserRolesBySchool(school?.id ?? '').has('material_didatico_relatorio_basico')
  }

  const handleTabChange = (tab: LogisticsTeachingMaterialTab) => {
    setActiveTab(tab.value)
    setOnQueryParam(tab.value, 'tab', 'replace')
  }
  useEffect(() => {
    if (!shouldShowReport) {
      goToReportsList()
    }
  }, [shouldShowReport])

  return (
    <Styled.Container>
      {isTeachingMaterialLogisticsEnabled ? (
        <div>
          <Styled.Header>
            <Button variation="ghost" startIcon={<ArrowBackIcon />} onClick={goToReportsList}>
              Voltar
            </Button>
          </Styled.Header>
          <Tabs.Root
            style={{ height: '100%' }}
            defaultValue={LOGISTICS_TABS[0].value}
            value={activeTab}
            onValueChange={value => {
              const tab = LOGISTICS_TABS.find(tab => tab.value === value)
              handleTabChange(tab)
            }}
          >
            <Tabs.List background="gray">
              {LOGISTICS_TABS.map(tab => (
                <Tabs.Trigger
                  key={tab.value}
                  value={tab.value}
                  iconStart={<Icon path={tab.icon} />}
                >
                  <Text variant="button-2" className="text-inherit">
                    {tab.label}
                  </Text>
                </Tabs.Trigger>
              ))}
            </Tabs.List>
            <Tabs.Content className="content" value={LOGISTICS_TEACHING_MATERIAL_TABS.SALES_REPORT}>
              <Styled.Title>Relatório de vendas do material didático</Styled.Title>

              <TeachingMaterialSalesReport />
            </Tabs.Content>
            <Tabs.Content
              className="content"
              value={LOGISTICS_TEACHING_MATERIAL_TABS.DELIVERY_REGISTRY}
            >
              <Styled.Title>Registro de entregas</Styled.Title>
              <Styled.SubTitle>
                Registre aqui as entregas de materiais didáticos. Apenas alunos que já pagaram o
                material aparecerão nessa lista.
              </Styled.SubTitle>
              <TeachingMaterialLogisticsPage />
            </Tabs.Content>
          </Tabs.Root>
        </div>
      ) : (
        <div>
          <Styled.Header style={{ marginBottom: 0 }}>
            <Button variation="ghost" startIcon={<ArrowBackIcon />} onClick={goToReportsList}>
              Voltar
            </Button>
            <Styled.Title
              style={{ marginTop: 0, paddingTop: '1rem 0', boxShadow: '0 1px 0 0 #e5e5e5' }}
            >
              Relatório de vendas do material didático
            </Styled.Title>
          </Styled.Header>
          <TeachingMaterialSalesReport />
        </div>
      )}
    </Styled.Container>
  )
}
