import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@olaisaac/design-system'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useJWT } from '@/shared/hooks/useJWT'
import { useGetIsaacPayIncomesReport } from '../../hooks/queries/isaacPayIncomesReport'

import { Loader } from '../../components/Loader'
import * as Styled from './styles'
import { PageWrapper } from '../../components/PageWrapper'

export const IsaacPayIncomeReport = () => {
  const { schoolSlug } = useSelectedSchool()
  const { push } = useHistory()
  const { hasPayoutReportAccess } = useJWT()
  const reportQuery = useGetIsaacPayIncomesReport()
  const [iframeStatus, setIframeStatus] = useState<typeof reportQuery.status | undefined>('loading')

  const goToReportsList = () => push(`/${schoolSlug}/relatorios`)

  useEffect(() => {
    if (!hasPayoutReportAccess) {
      goToReportsList()
    }
  }, [hasPayoutReportAccess])

  return (
    <PageWrapper status={reportQuery.status}>
      <Styled.Header>
        <Button variation="ghost" startIcon={<ArrowBackIcon />} onClick={goToReportsList}>
          Voltar
        </Button>
        <Styled.Title>Visão de recebimentos</Styled.Title>
      </Styled.Header>

      {iframeStatus === 'loading' ? <Loader /> : null}

      <Styled.Iframe
        id="isaac-pay-income-report"
        src={reportQuery.data?.url}
        style={{ display: iframeStatus === 'success' ? 'block' : 'none' }}
        onLoad={() => setIframeStatus('success')}
      />
    </PageWrapper>
  )
}
