import { GenericError } from '@/shared/components/GenericError'
import { CircularLoadingPlaceholder } from '@/shared/components/CircularLoadingPlaceholder'

type TabWrapperProps = {
  isVisible?: boolean
  onRetry?: () => void
  status: 'error' | 'loading' | 'success'
}

export const TabWrapper = ({ status, onRetry, isVisible = true }: TabWrapperProps) => {
  if (status === 'loading' && isVisible) {
    return <CircularLoadingPlaceholder />
  }

  if (status === 'error' && isVisible && onRetry) {
    return <GenericError onRetry={onRetry} />
  }

  return null
}
