import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApiClient } from '@/shared/hooks'
import { formatDateWithTimezone } from '@/shared/utils'
import { PaginationType } from '@/shared/hooks/usePagination/types'
import { productDeliveriesService } from '@/modules/account-reports/services/product_deliveries'
import {
  CreateProductDeliveryRequest,
  CreateProductDeliveryResponse,
  ProductDelivery,
} from '@/modules/account-reports/services/product_deliveries/types'
import { shortProductName } from './utils/shortProductName'

type Props = {
  nameFilter: string
  pagination: PaginationType
}

type Result = {
  error: boolean
  handleCreateDelivery: (
    body: CreateProductDeliveryRequest
  ) => Promise<CreateProductDeliveryResponse>
  loading: boolean
  productDeliveries: ProductDelivery[]
  refetch: () => void
  total: number
}

export const useGetTeachingMaterialLogistics = ({ nameFilter, pagination }: Props): Result => {
  const { school } = useSelectedSchool()
  const { apiClient } = useApiClient()
  const service = productDeliveriesService(apiClient.privateApi)

  const { data: response, isLoading, isError, refetch } = useQuery(
    ['product-delivery', 'teaching-material', school?.id, nameFilter, JSON.stringify(pagination)],
    async () => {
      if (!school?.id) return

      return service.fetchProductDeliveries({
        schoolId: school.id,
        perPage: pagination.itemsPerPage,
        page: pagination.page,
        nameFilter,
      })
    },
    { enabled: !!school?.id }
  )

  const students = useMemo(() => {
    if (!response?.data?.students) return []

    const result = response.data.students.map(st => ({
      ...st,
      product_name: shortProductName(st.product_name),
      deliveries: st.deliveries.map(d => ({
        ...d,
        delivery_date: formatDateWithTimezone(d.delivery_date, 'DD.MM.YYYY - HH[h]mm'),
      })),
    }))

    return result
  }, [response?.data?.students])

  const handleCreateDelivery = (body: CreateProductDeliveryRequest) => {
    return service.createProductDelivery(body)
  }

  return {
    productDeliveries: students,
    total: response?.pagination?.total ?? 0,
    error: isError,
    loading: isLoading,
    refetch,
    handleCreateDelivery,
  }
}
