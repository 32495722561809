import { useState } from 'react'
import dayjs from 'dayjs'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useApiClient, useSnackbar } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { accountReportsService } from '@/modules/account-reports/services/account-reports'

import { downloadFile } from '@/shared/utils'

import { ReportTypes } from '../../models/enums/ReportTypes.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export const useDownloadTicketsSummaryReport = () => {
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setMessage, setIsOpen } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const { apiClient } = useApiClient()
  const service = accountReportsService(apiClient.privateApi)

  const sendEvent = (isDownloadSuccessful: boolean) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.DOWNLOAD_FILE,
        scope: EventDispatcherEventScopes.REPORTS,
        action: 'file_download',
        customProperties: {
          $report: ReportTypes.TICKET_SUMMARY,
          $is_download_successful: isDownloadSuccessful,
        },
      })
  }

  const downloadTicketSummaryReport = async (selectedYearAndMonth?: Date) => {
    if (!selectedYearAndMonth) {
      return
    }

    setIsLoading(true)

    let isDownloadSuccessful = true

    try {
      const reference = dayjs(selectedYearAndMonth).utc().format('YYYY-MM-DD')
      const ticketsSummaryReport = async () => {
        const { data } = await service.downloadTicketsSummary(school?.id ?? '', reference)
        return data
      }
      await downloadFile(`relatório-de-atendimentos-${reference}`, 'pdf', ticketsSummaryReport)
    } catch (error) {
      isDownloadSuccessful = false
      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      setIsLoading(false)
      sendEvent(isDownloadSuccessful)
    }
  }

  return {
    downloadTicketSummaryReport,
    isLoading,
  }
}
